<template>
  <div>
    <div class="data-header">
      <span class="data-min-title">{{cnName}}</span>
    </div>
    <el-row class="left-top-main">
      <el-col :span="6" class="main-item">
        <div>人员报警数</div>
        <div class="main-item-bg">
          <span class="main-item-bg-test">{{ myData.personnelAlarmCount || 0 }}</span>
        </div>
      </el-col>
      <el-col :span="6" class="main-item">
        <div>人员预警数</div>
        <div class="main-item-bg">
          <span class="main-item-bg-test">{{ myData.personnelPreWarningCount || 0  }}</span>
        </div>
      </el-col>
      <el-col :span="6" class="main-item">
        <div>体温异常数</div>
        <div class="main-item-bg">
          <span class="main-item-bg-test">{{ myData.temperatureAbnormalCount || 0  }}</span>
        </div>
      </el-col>
      <el-col :span="6" class="main-item">
        <div>心率异常数</div>
        <div class="main-item-bg">
          <span class="main-item-bg-test">{{ myData.heartRateAbnormalCount || 0  }}</span>
        </div>
      </el-col>
      <el-col :span="24" class="left-top-tabel">
        <div class="data-content-tabel">
          <div class="left-tabel">
            <div class="table-title">
              <div class="plate-number">姓名</div>
              <div class="alarm-name">事件类型</div>
              <div class="alarm-time">时间</div>
            </div>
            <vue-seamless :data="myData.eventItems" :class-option="optionHover" class="auto-scorll-table">
              <ul class="seamless-warp">
                <li v-for="(item, index) in myData.eventItems" :key="index" class="seamless-warp-li">
                  <div class="plate-number1">{{ item.name }}</div>
                  <div class="alarm-name1">{{ item.event }}</div>
                  <div class="alarm-time1">{{ item.eventTime|str2ymdhm }}</div>
                </li>
              </ul>
            </vue-seamless>
          </div>
          <el-carousel indicator-position="none" :interval="4000" type="card" height="calc(23vh - 100px)" class="carousel">
            <el-carousel-item v-for="(item, index) in myData.imageList" :key="index">
              <div class="image-bg">
                <img :src="item" alt="" class="image-item">
              </div>
              <!-- <h3 class="medium">{{ item }}</h3> -->
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- <el-table
          :data="myData.eventItems"
          size="mini"
          :row-style="rowStyle"
          :header-cell-style="headerRowStyle"
          style="background-color: transparent;"
          align="center"
          height="100%"
        >
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="event" label="事件类型"></el-table-column>
          <el-table-column label="时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.eventTime|str2ymdhm}}</span>
            </template>
          </el-table-column>
        </el-table> -->
      </el-col>
      <div class="main-item"></div>
    </el-row>
    <div class="data-content"></div>
  </div>
</template>
<script>
import mixin from "./mixin-all";
import vueSeamless from 'vue-seamless-scroll'
export default {
  mixins: [mixin],
  components: {
    vueSeamless
  },
  data() {
    return {
      //↓/////钩子字段//////////////
      name: "ssrysj",
      cnName: "实时人员事件",
      api: "/sanitation/dataCloudService/getPersonnelEvent",
      isMock: false,
      comData: [],
      //↑////钩子字段//////////////
      debug:0,
    };
  },
  computed:{
      // table(){
      //     return this.comData.list;
      // },
      myData(){
        return this.comData
      },
      optionHover () {
        return {
          step: 0.2,
          limitMoveNum: 7,
          openWatch: true, 
          waitTime: 3000
        }
      }
  },
  mounted() {
    let self = this;
    if(self.isMock){
      self.comData = getMockData();
      let timer = setInterval(async () => {
        self.comData = getMockData();
      }, 6000);
      
      self.$once("hook:beforeDestory", () => {
        clearInterval(timer);
        timer = null;
      });
    }
  }
};
function getMockData() {
  return {
    eventItems: [
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
      {
        name: '张三',
        event: '测试事件',
        eventTime: 1594286325
      },
    ],
    personnelAlarmCount: 12,
    personnelPreWarningCount: 18,
    temperatureAbnormalCount: 9,
    heartRateAbnormalCount: 11,

  }
};
</script>
<style scoped lang='sass'>
.data-content-tabel
  width: 100%
  height: calc(56vh - 230px)
  .left-tabel
    height: calc(56vh - 230px)
    width: 100%
    .table-title
      width: 100%
      background: rgba(8,49,126,1)
      border-top: 1px solid rgba(8,155,254,1)
      color: rgba(0,252,255,1)
      font-size: 12px
      padding: 10px
      font-weight: bold
      .plate-number 
        width: 30%
        display: inline-block
        vertical-align: middle
      .alarm-name
        width: 30%
        display: inline-block
        vertical-align: middle
      .alarm-time
        width: 40%
        display: inline-block
        vertical-align: middle
    .auto-scorll-table
      width: 100%
      height: calc(56vh - 265px)
      overflow: hidden
      .seamless-warp
        width: 100%
        height: 100%
        font-size: 12px
        font-weight: bold
        color: rgba(255, 255, 255, 0.8)
        li:nth-of-type(even)
          background: rgba(16,112,217,0.3)
        .seamless-warp-li
          padding: 8px 10px
          .plate-number1 
            width: 30%
            display: inline-block
            vertical-align: middle
          .alarm-name1
            width: 30%
            display: inline-block
            vertical-align: middle
          .alarm-time1
            width: 40%
            display: inline-block
          vertical-align: middle
</style>